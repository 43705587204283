.App {
  text-align: center;
  display: flex;
  width: 100%;
  height: 100%;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
.App-header {
  width: 100%;
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.icon-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

.icon-badge {
  top: 6px !important;
  right: 6px !important;
}

body {
  margin: 0;
  font-family: "DM Sans", Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-y: hidden;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.login-layout,
.reset-password-layout,
.signup-layout,
.confirmation-layout {
  display: flex;
  width: 100%;
  height: 100vh;
}

.authentication {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 80px;
  gap: 24px;
}

.login-footer-link {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 8px;
}

.login-marketing-wrapper {
  height: 100%;
}
.login-marketing-wrapper .login-marketing {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  height: 90%;
  box-sizing: border-box;
  gap: 40px;
  padding: 120px 120px 60px 120px;
}
.login-marketing-wrapper .login-marketing img {
  width: min(100%, 292px);
}
.login-marketing-wrapper .login-marketing h3,
.login-marketing-wrapper .login-marketing h6 {
  font-weight: 700;
}

.login-form,
.signup {
  /* Authentication */
  /* Auto layout */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 24px;
  padding-bottom: 8px;
  width: 360px;
}
.login-form .login-button,
.signup .login-button {
  width: auto;
}
.login-form .login-footer:last-child .login-footer-link,
.signup .login-footer:last-child .login-footer-link {
  padding-bottom: 4px;
}
.login-form .link,
.signup .link {
  text-decoration: none;
}
.login-form .terms-of-service,
.signup .terms-of-service {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  text-align: left;
}
.login-form .terms-of-service .terms-of-service-text,
.signup .terms-of-service .terms-of-service-text {
  cursor: pointer;
}
.login-form .terms-of-service-box,
.signup .terms-of-service-box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 12px;
}
.login-form .signup-loading,
.signup .signup-loading {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 16px;
}
.login-form .error-409 a,
.signup .error-409 a {
  color: currentColor;
}

.form-title-box {
  /* Title */
  /* Auto layout */
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  /* Inside auto layout */
  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
}

.form-footer {
  /* Frame 2 */
  /* Auto layout */
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 8px;
  width: 360px;
  height: 48px;
  /* Inside auto layout */
  flex: none;
  order: 4;
  align-self: stretch;
  flex-grow: 0;
}

.left-table-footer {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  gap: 16px;
  height: 36px;
}

.show-select .MuiNativeSelect-select {
  background: #ffffff;
  border: 1px solid #e5e7eb;
  box-shadow: 0px 1px 2px rgba(31, 41, 55, 0.08);
  border-radius: 4px;
  padding: 8px;
  font-size: 13px;
}
.show-select .MuiNativeSelect-select:focus {
  background: #ffffff;
}

.table-box2 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 12px;
  width: 100%;
  height: 90%;
}

.table-box {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 0px;
  gap: 12px;
  width: 100%;
  height: 90%;
}
.table-box .project-checkbox {
  width: 20px;
  height: 20px;
  margin-right: 4px;
}

.table-box-0 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0px;
  gap: 32px;
  width: 100%;
  height: 100%;
}
.table-box-0 .zero-projects {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 16px;
}
.table-box-0 .zero-projects .table-box-0-icon {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 64px;
  height: 64px;
  border-radius: 1200px;
}
.table-box-0 .zero-projects-buttons {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 32px;
}

.pagination-box {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  padding: 0px;
  width: 100%;
}

.landing-icon {
  left: 90px;
  top: 12px;
  border-radius: 0px;
  width: 16px;
  height: 16px;
}

.landing-icon {
  left: 90px;
  top: 12px;
  border-radius: 0px;
  width: 16px;
  height: 16px;
}

.projects-tab {
  height: 40px;
  width: 1320px;
  left: 24px;
  top: 24px;
  border-radius: 0px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 0px;
  gap: 10px;
}

.tab-bar {
  height: 40px;
  width: 263px;
  left: 0px;
  top: 0px;
  border-radius: 0px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px;
  gap: 24px;
}

.landing-box {
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow: auto;
  width: 100%;
}

.body-box {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 16px;
  overflow-y: auto;
  height: 100%;
}

.start-project-btn .MuiButton-root {
  text-transform: none;
}

.App-sideBar {
  height: -1%;
  width: 72px;
  border-radius: 0px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 16px 0 16px 0;
  gap: 16px;
}

.box {
  height: 100%;
  width: 250px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
}
.box .general-drawer-body-box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
}
.box .general-drawer-body-box .logo-full {
  height: 32px;
}
.box .general-drawer-body-box .project-full {
  text-align: left;
  padding-left: 0;
}
.box .general-drawer-body-box .project-full .left-align-ListItem {
  padding-left: 0;
}

.App-sideBar .MuiBox-root {
  height: 100%;
}

.sidebar-body {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.sidebar-body .folder-icon {
  height: 40px;
  margin-top: 20px;
}
.sidebar-body .settings-icon {
  height: 40px;
  margin-top: 20px;
}

.sidebar-icon {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 4px;
  width: 40px;
  height: 40px;
  border-radius: 4px;
}

.sidebar-logo {
  height: 32px;
  background: none;
}

.MuiPaper-root .css-12ewdr9 {
  display: flex;
  height: 100%;
  flex-direction: column;
}

.general-drawer-footer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
}

.general-drawer-footer-box {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  padding-left: 0px;
}
.general-drawer-footer-box .logout {
  width: 100%;
  padding-left: 0px;
}
.general-drawer-footer-box .close-nav {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  padding-right: 16px;
  padding-left: 0px;
}

.tb-left {
  height: 36px;
  width: 586px;
  left: 0px;
  top: 0px;
  border-radius: 0px;
  /* Left */
  /* Auto layout */
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  gap: 8px;
  width: 586px;
  height: 36px;
  /* Inside auto layout */
  flex: none;
  order: 0;
  flex-grow: 0;
}
.tb-left .search-field .MuiOutlinedInput-root {
  font-size: 14px;
  border: 1px solid #e5e7eb;
  border-radius: 4;
}
.tb-left .search-field .MuiOutlinedInput-root > fieldset {
  border: none;
}

.header-box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 40px 0px;
  gap: 24px;
  width: 100%;
  height: 194px;
}

.header-form-box {
  display: flex;
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  width: 100%;
}
.header-form-box .header-option {
  width: 350px;
  border: 1px solid #e5e7eb;
  background-color: white;
}
.header-form-box .header-option .MuiNativeSelect-select {
  height: 40px;
  padding-top: 0;
  padding-bottom: 0;
  padding-left: 1rem;
}
.header-form-box .header-option .MuiNativeSelect-select:focus {
  background-color: white;
}
.header-form-box .header-option .MuiNativeSelect-nativeInput {
  border: none;
}

.project-drawer {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  position: relative;
  width: 100%;
  height: 100%;
}

.drawer-header {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 24px 24px 16px;
  box-sizing: border-box;
}
.drawer-header .card-header {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px;
  gap: 10px;
}
.drawer-header .card-header .card-title {
  font-size: 18px;
}
.drawer-header .card-text {
  margin-top: 1rem;
  font-size: 15px;
}

.drawer-internal-wrapper {
  height: 100%;
  width: 100%;
  overflow: hidden;
}
.drawer-internal-wrapper .visit-modal-header {
  position: absolute;
  width: 100%;
  top: 0px;
  left: 0px;
  height: 82px;
  right: 0px;
  overflow: hidden;
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  padding: 24px 24px 16px;
  border-bottom: 2px lightgrey solid;
}
.drawer-internal-wrapper .visit-modal-header .card-header {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
}
.drawer-internal-wrapper .visit-modal-header .card-header .card-title {
  font-size: 18px;
}
.drawer-internal-wrapper .visit-modal-header .card-header .categorize-button {
  width: fit-content;
  line-height: 24px;
  letter-spacing: 0.400000006px;
  margin-right: 6px;
  white-space: nowrap;
  text-transform: none;
}
.drawer-internal-wrapper .visit-modal-header .card-text {
  margin-top: 1rem;
  font-size: 15px;
}
.drawer-internal-wrapper .modal-content-visits {
  padding: 16px;
  position: absolute;
  top: 82px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  overflow: auto;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  gap: 24px;
}
.drawer-internal-wrapper .modal-content-visits .modal-content-visits-right {
  width: 60%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: initial;
  gap: 16px;
}
.drawer-internal-wrapper .modal-content-visits .modal-content-visits-right .modal-content-clinical-notes {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 0px;
  gap: 16px;
}
.drawer-internal-wrapper .modal-content-visits .modal-content-visits-left {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 0px;
  gap: 16px;
  width: min(40%, 500px);
}
.drawer-internal-wrapper .modal-content-visits .modal-content-visits-left .visit-drawer-card,
.drawer-internal-wrapper .modal-content-visits .modal-content-visits-right .visit-drawer-card {
  width: 100%;
}
.drawer-internal-wrapper .modal-content-visits .modal-content-visits-left .visit-drawer-card .diagnosis-codes,
.drawer-internal-wrapper .modal-content-visits .modal-content-visits-left .visit-drawer-card .procedure-codes,
.drawer-internal-wrapper .modal-content-visits .modal-content-visits-left .visit-drawer-card .clinical-notes,
.drawer-internal-wrapper .modal-content-visits .modal-content-visits-right .visit-drawer-card .diagnosis-codes,
.drawer-internal-wrapper .modal-content-visits .modal-content-visits-right .visit-drawer-card .procedure-codes,
.drawer-internal-wrapper .modal-content-visits .modal-content-visits-right .visit-drawer-card .clinical-notes {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 8px;
}
.drawer-internal-wrapper .modal-content-visits .modal-content-visits-left .visit-drawer-card .diagnosis-codes .code,
.drawer-internal-wrapper .modal-content-visits .modal-content-visits-left .visit-drawer-card .procedure-codes .code,
.drawer-internal-wrapper .modal-content-visits .modal-content-visits-left .visit-drawer-card .clinical-notes .code,
.drawer-internal-wrapper .modal-content-visits .modal-content-visits-right .visit-drawer-card .diagnosis-codes .code,
.drawer-internal-wrapper .modal-content-visits .modal-content-visits-right .visit-drawer-card .procedure-codes .code,
.drawer-internal-wrapper .modal-content-visits .modal-content-visits-right .visit-drawer-card .clinical-notes .code {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
}
.drawer-internal-wrapper .modal-content-visits .modal-content-visits-left .visit-drawer-card .diagnosis-codes .empty-data,
.drawer-internal-wrapper .modal-content-visits .modal-content-visits-left .visit-drawer-card .procedure-codes .empty-data,
.drawer-internal-wrapper .modal-content-visits .modal-content-visits-left .visit-drawer-card .clinical-notes .empty-data,
.drawer-internal-wrapper .modal-content-visits .modal-content-visits-right .visit-drawer-card .diagnosis-codes .empty-data,
.drawer-internal-wrapper .modal-content-visits .modal-content-visits-right .visit-drawer-card .procedure-codes .empty-data,
.drawer-internal-wrapper .modal-content-visits .modal-content-visits-right .visit-drawer-card .clinical-notes .empty-data {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
  width: 100%;
}
.drawer-internal-wrapper .modal-content-visits .modal-content-visits-left .visit-drawer-card .diagnosis-codes .skeleton,
.drawer-internal-wrapper .modal-content-visits .modal-content-visits-left .visit-drawer-card .procedure-codes .skeleton,
.drawer-internal-wrapper .modal-content-visits .modal-content-visits-left .visit-drawer-card .clinical-notes .skeleton,
.drawer-internal-wrapper .modal-content-visits .modal-content-visits-right .visit-drawer-card .diagnosis-codes .skeleton,
.drawer-internal-wrapper .modal-content-visits .modal-content-visits-right .visit-drawer-card .procedure-codes .skeleton,
.drawer-internal-wrapper .modal-content-visits .modal-content-visits-right .visit-drawer-card .clinical-notes .skeleton {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
}
.drawer-internal-wrapper .modal-content-visits .modal-content-visits-left .visit-drawer-card .diagnosis-codes .skeleton .skeleton-row,
.drawer-internal-wrapper .modal-content-visits .modal-content-visits-left .visit-drawer-card .procedure-codes .skeleton .skeleton-row,
.drawer-internal-wrapper .modal-content-visits .modal-content-visits-left .visit-drawer-card .clinical-notes .skeleton .skeleton-row,
.drawer-internal-wrapper .modal-content-visits .modal-content-visits-right .visit-drawer-card .diagnosis-codes .skeleton .skeleton-row,
.drawer-internal-wrapper .modal-content-visits .modal-content-visits-right .visit-drawer-card .procedure-codes .skeleton .skeleton-row,
.drawer-internal-wrapper .modal-content-visits .modal-content-visits-right .visit-drawer-card .clinical-notes .skeleton .skeleton-row {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}
.drawer-internal-wrapper .modal-content-visits .modal-content-visits-left .visit-drawer-card .diagnosis-codes .clinical-notes-insight-content-title,
.drawer-internal-wrapper .modal-content-visits .modal-content-visits-left .visit-drawer-card .procedure-codes .clinical-notes-insight-content-title,
.drawer-internal-wrapper .modal-content-visits .modal-content-visits-left .visit-drawer-card .clinical-notes .clinical-notes-insight-content-title,
.drawer-internal-wrapper .modal-content-visits .modal-content-visits-right .visit-drawer-card .diagnosis-codes .clinical-notes-insight-content-title,
.drawer-internal-wrapper .modal-content-visits .modal-content-visits-right .visit-drawer-card .procedure-codes .clinical-notes-insight-content-title,
.drawer-internal-wrapper .modal-content-visits .modal-content-visits-right .visit-drawer-card .clinical-notes .clinical-notes-insight-content-title {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 8px;
  padding: 0px;
}
.drawer-internal-wrapper .modal-content-visits .modal-content-visits-left .visit-drawer-card .diagnosis-codes .clinical-notes-insight-items,
.drawer-internal-wrapper .modal-content-visits .modal-content-visits-left .visit-drawer-card .procedure-codes .clinical-notes-insight-items,
.drawer-internal-wrapper .modal-content-visits .modal-content-visits-left .visit-drawer-card .clinical-notes .clinical-notes-insight-items,
.drawer-internal-wrapper .modal-content-visits .modal-content-visits-right .visit-drawer-card .diagnosis-codes .clinical-notes-insight-items,
.drawer-internal-wrapper .modal-content-visits .modal-content-visits-right .visit-drawer-card .procedure-codes .clinical-notes-insight-items,
.drawer-internal-wrapper .modal-content-visits .modal-content-visits-right .visit-drawer-card .clinical-notes .clinical-notes-insight-items {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 8px;
}
.drawer-internal-wrapper .modal-content-visits .modal-content-visits-left .visit-drawer-card .diagnosis-codes .clinical-notes-insight-items .clinical-notes-insight-item,
.drawer-internal-wrapper .modal-content-visits .modal-content-visits-left .visit-drawer-card .procedure-codes .clinical-notes-insight-items .clinical-notes-insight-item,
.drawer-internal-wrapper .modal-content-visits .modal-content-visits-left .visit-drawer-card .clinical-notes .clinical-notes-insight-items .clinical-notes-insight-item,
.drawer-internal-wrapper .modal-content-visits .modal-content-visits-right .visit-drawer-card .diagnosis-codes .clinical-notes-insight-items .clinical-notes-insight-item,
.drawer-internal-wrapper .modal-content-visits .modal-content-visits-right .visit-drawer-card .procedure-codes .clinical-notes-insight-items .clinical-notes-insight-item,
.drawer-internal-wrapper .modal-content-visits .modal-content-visits-right .visit-drawer-card .clinical-notes .clinical-notes-insight-items .clinical-notes-insight-item {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 0px;
  gap: 16px;
}
.drawer-internal-wrapper .modal-content-visits .modal-content-visits-left .visit-drawer-card .diagnosis-codes .clinical-notes-insight-items .clinical-notes-insight-item .visit-card-label,
.drawer-internal-wrapper .modal-content-visits .modal-content-visits-left .visit-drawer-card .procedure-codes .clinical-notes-insight-items .clinical-notes-insight-item .visit-card-label,
.drawer-internal-wrapper .modal-content-visits .modal-content-visits-left .visit-drawer-card .clinical-notes .clinical-notes-insight-items .clinical-notes-insight-item .visit-card-label,
.drawer-internal-wrapper .modal-content-visits .modal-content-visits-right .visit-drawer-card .diagnosis-codes .clinical-notes-insight-items .clinical-notes-insight-item .visit-card-label,
.drawer-internal-wrapper .modal-content-visits .modal-content-visits-right .visit-drawer-card .procedure-codes .clinical-notes-insight-items .clinical-notes-insight-item .visit-card-label,
.drawer-internal-wrapper .modal-content-visits .modal-content-visits-right .visit-drawer-card .clinical-notes .clinical-notes-insight-items .clinical-notes-insight-item .visit-card-label {
  font-weight: 700;
  width: 150px;
  min-width: 150px;
}
.drawer-internal-wrapper .modal-content-visits .modal-content-visits-left .modal-content-right-header,
.drawer-internal-wrapper .modal-content-visits .modal-content-visits-right .modal-content-right-header {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.drawer-internal-wrapper .modal-content-visits .modal-content-visits-left .modal-content-right-header .modal-content-visits-card,
.drawer-internal-wrapper .modal-content-visits .modal-content-visits-right .modal-content-right-header .modal-content-visits-card {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 8px;
  gap: 16px;
}
.drawer-internal-wrapper .modal-content-visits .modal-content-visits-left .encounter-type,
.drawer-internal-wrapper .modal-content-visits .modal-content-visits-right .encounter-type {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding-top: 8px;
  gap: 46px;
  width: 100%;
}
.drawer-internal-wrapper .modal-content-visits .modal-content-visits-left .encounter-type .label,
.drawer-internal-wrapper .modal-content-visits .modal-content-visits-right .encounter-type .label {
  width: 75px;
  font-weight: 700;
  font-size: 14px;
  line-height: 143%;
}
.drawer-internal-wrapper .modal-content-visits .modal-content-visits-left .encounter-type .type,
.drawer-internal-wrapper .modal-content-visits .modal-content-visits-right .encounter-type .type {
  overflow-wrap: break-word;
  overflow: hidden;
}
.drawer-internal-wrapper .mark-visit-modal {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 0px;
  position: relative;
  width: 600px;
  height: fit-content;
}

.drawer-options {
  width: 100%;
}

.modal-content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  gap: 24px;
  overflow-y: scroll;
  overflow-y: overlay;
  padding: 24px;
  flex-grow: 1;
  width: 100%;
  max-height: 100%;
  box-sizing: border-box;
  flex-grow: 1;
}

.modal-content2 {
  display: flex;
  padding: 24px;
  flex-grow: 1;
  width: 100%;
  box-sizing: border-box;
  overflow-y: auto;
  overflow-x: hidden;
}

.activity-card-content {
  width: 100%;
}
.activity-card-content .activity-card-note {
  padding: 8px;
  border-radius: 4px;
}

.modal-header-left {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  gap: 8px;
}

.modal-list {
  /* Auto layout */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  padding: 0px;
  gap: 24px;
  width: 100%;
}

.drawer-label {
  padding-bottom: 8px;
}

.drag-drop-box {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 8px;
  width: 100%;
  max-height: 100%;
}

.drag-drop-tittle {
  /* Field Label */
  /* Auto layout */
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  /* Inside auto layout */
  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
}

.drag-drop-text1 {
  /* Label */
  width: 170px;
  height: 20px;
  /* text-sm/Regular */
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  /* identical to box height, or 143% */
  display: flex;
  align-items: center;
  /* gray/500 */
  color: #6b7280;
  /* Inside auto layout */
  flex: none;
  order: 0;
  flex-grow: 0;
}

.drag-drop-list-title {
  /* Label */
  width: 237px;
  height: 16px;
  /* text-xs/Medium */
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  /* identical to box height, or 133% */
  display: flex;
  align-items: center;
  /* gray/500 */
  color: #6b7280;
  /* Inside auto layout */
  flex: none;
  order: 0;
  flex-grow: 1;
}

.tl-paper {
  width: 236px;
  height: 230px;
  overflow: auto;
}

.list-box {
  /* Frame 1688 */
  width: 100%;
  /* Auto layout */
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  gap: 24px;
  max-height: 100%;
  /* Inside auto layout */
  flex: none;
  order: 0;
  flex-grow: 1;
}

.drawer-date {
  /* Field Base */
  /* Auto layout */
  left: -5px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px;
  padding-left: 0px;
  gap: 8px;
  width: 100%;
  max-height: 100%;
  /* Inside auto layout */
  flex: none;
  order: 0;
  flex-grow: 1;
}

.date-arrow-icon {
  /* Prefix */
  gap: -8px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  /* Inside auto layout */
  flex: none;
  flex-grow: 0;
}

.drawer-box-last-label {
  /* Field Caption */
  /* Auto layout */
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  gap: 8px;
  width: 552px;
  height: 16px;
  /* Inside auto layout */
  flex: none;
  order: 2;
  align-self: stretch;
  flex-grow: 0;
}

.last-label {
  /* Text */
  width: 240px;
  height: 16px;
  /* text-xs/Regular */
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  /* identical to box height, or 133% */
  display: flex;
  align-items: center;
  /* gray/500 */
  color: #6b7280;
  /* Inside auto layout */
  flex: none;
  order: 1;
  flex-grow: 0;
}

.tl-searchbar {
  /* Field */
  /* Auto layout */
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  width: 200px;
  height: 36px;
  /* Inside auto layout */
  flex: none;
  order: 0;
  flex-grow: 1;
}

.tl-search-icon-box {
  /* Icon */
  left: 1.5rem;
  top: 1rem;
  position: absolute;
  width: 20px;
  height: 20px;
  /* Inside auto layout */
  flex: none;
  order: 0;
  flex-grow: 0;
}

.tl-text {
  /* Placeholder... */
  position: absolute;
  left: 3.5rem;
  top: 1rem;
  width: 140px;
  height: 20px;
  /* text-sm/Regular */
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  /* identical to box height, or 143% */
  display: flex;
  align-items: center;
  /* gray/300 */
  border: none;
  color: #d1d5db;
  /* Inside auto layout */
  flex: none;
  order: 1;
  flex-grow: 1;
}

.tl-box {
  /* Frame 1688 */
  /* Auto layout */
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  gap: 24px;
  width: 552px;
  height: 354px;
  /* Inside auto layout */
  flex: none;
  order: 1;
  flex-grow: 0;
}

.tl-button {
  /* chevron-right */
  width: 32px;
  height: 32px;
  /* white */
  background: #ffffff;
  /* gray/200 */
  border: 1px solid #e5e7eb;
  /* shadow-base */
  box-shadow: 0px 1px 2px rgba(31, 41, 55, 0.08);
  border-radius: 4px;
  /* Inside auto layout */
  flex: none;
  order: 0;
  flex-grow: 0;
}

.tl-button-box {
  /* Frame 1694 */
  /* Auto layout */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 0px;
  gap: 8px;
  width: 32px;
  height: 54px;
  /* Inside auto layout */
  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
}

.date-box .MuiOutlinedInput-root {
  height: 36px;
}

.list-box .MuiGrid-root {
  justify-content: space-between;
}

.input-default {
  width: 100%;
}

.list-box .MuiGrid-root {
  -webkit-flex-wrap: nowrap;
  flex-wrap: nowrap;
}

.modal-content .MuiList-root {
  width: 100%;
  padding: 0;
}

.modal-content .MuiList-root .MuiFormControl-root {
  width: 100%;
}

.modal-content-title-box {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  padding: 8px 16px;
}
.modal-content-title-box .column-1 {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
}
.modal-content-title-box .column-1 .modal-content-boldtext {
  font-weight: bold;
}
.modal-content-title-box .column-2 {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
}

.modal-content-subtitle-box {
  padding: 8px 16px;
}

.modal-content-button {
  display: flex;
  justify-content: flex-end;
}
.modal-content-button .categorize-button {
  width: fit-content;
  line-height: 24px;
  letter-spacing: 0.400000006px;
  margin-right: 6px;
  margin-top: 6px;
  margin-bottom: 6px;
  white-space: nowrap;
  text-transform: none;
}

.drawer-card {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  height: 100%;
  width: 100%;
}
.drawer-card .drawer-header {
  box-sizing: border-box;
  width: 100%;
}
.drawer-card .drawer-content {
  box-sizing: border-box;
  flex-grow: 1;
  max-height: 100%;
  overflow-y: scroll;
  padding: 24px;
  width: 100%;
}

.details-container {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  width: 100%;
  height: 100dvh;
}

.details-body {
  width: 100%;
}

.details-box {
  /* Project Header */
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0px 8px;
  gap: 8px;
  position: relative;
  width: 100%;
  height: 96px;
}
.details-box .tab {
  min-height: auto;
}

.details-box-left {
  overflow: hidden;
  padding: 16px 0px 0px;
  width: fit-content;
  height: 96px;
}

.details-box-left-top {
  height: 32px;
  max-width: 100%;
}

.details-box-right {
  padding: 0px;
  height: 36px;
  margin-right: 10px;
}
.details-box-right .filter-button {
  width: 155px;
  height: 36px;
  white-space: nowrap;
  text-transform: none;
}

.model-patient-container,
.geographic-breakdown-container,
.demographic-breakdown-container,
.stats-container {
  width: 100%;
  max-width: 900px;
  box-sizing: border-box;
}
.model-patient-container-graph,
.geographic-breakdown-container-graph,
.demographic-breakdown-container-graph,
.stats-container-graph {
  height: 600px;
}
.model-patient-container .demographic-breakdown-card .skeleton,
.geographic-breakdown-container .demographic-breakdown-card .skeleton,
.demographic-breakdown-container .demographic-breakdown-card .skeleton,
.stats-container .demographic-breakdown-card .skeleton {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  gap: 24px;
}
.model-patient-container .demographic-breakdown-card .skeleton .skeleton-row,
.geographic-breakdown-container .demographic-breakdown-card .skeleton .skeleton-row,
.demographic-breakdown-container .demographic-breakdown-card .skeleton .skeleton-row,
.stats-container .demographic-breakdown-card .skeleton .skeleton-row {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}
.model-patient-container .preview-mode-charts,
.geographic-breakdown-container .preview-mode-charts,
.demographic-breakdown-container .preview-mode-charts,
.stats-container .preview-mode-charts {
  min-height: 500px;
  max-height: 500px;
  overflow: hidden;
  position: relative;
}
.model-patient-container .preview-mode-charts .preview-mode-backdrop,
.geographic-breakdown-container .preview-mode-charts .preview-mode-backdrop,
.demographic-breakdown-container .preview-mode-charts .preview-mode-backdrop,
.stats-container .preview-mode-charts .preview-mode-backdrop {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #ffffff 30.21%);
  backdrop-filter: blur(1px);
  box-sizing: border-box;
  gap: 15px;
  height: 100%;
  top: 0;
  padding: 24px;
  position: absolute;
  width: 100%;
  z-index: 9;
}
.model-patient-container .preview-mode-charts .preview-mode-backdrop .preview-mode-backdrop-button,
.geographic-breakdown-container .preview-mode-charts .preview-mode-backdrop .preview-mode-backdrop-button,
.demographic-breakdown-container .preview-mode-charts .preview-mode-backdrop .preview-mode-backdrop-button,
.stats-container .preview-mode-charts .preview-mode-backdrop .preview-mode-backdrop-button {
  text-transform: none;
}
.model-patient-container .helper-text,
.geographic-breakdown-container .helper-text,
.demographic-breakdown-container .helper-text,
.stats-container .helper-text {
  text-align: center;
}

.contact-us-modal-card {
  position: absolute;
  height: 586px;
  width: 600px;
  top: 50% !important;
  left: 50% !important;
  transform: translate(-50%, -50%);
}
.contact-us-modal-card:focus-visible {
  outline: none;
}

.order-level-card .empty-data {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
  width: 100%;
  text-align: center;
}

.order-level-card-contents {
  height: auto;
  max-height: 400px;
  width: 100%;
  border: none;
}
.order-level-card-contents .order-level-table {
  border: none;
  max-height: 400px;
}
.order-level-card-contents .order-level-table .title {
  font-size: 16px;
  font-weight: 400;
}
.order-level-card-contents .order-level-table highlight {
  background: rgba(0, 73, 84, 0.3);
  padding: 0 2px;
}
.order-level-card-contents .order-level-table .priority {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.order-level-card-contents .order-level-table .MuiDataGrid-columnHeaderTitle {
  white-space: normal;
  line-height: normal;
  text-overflow: ellipsis;
  overflow: hidden;
  font-weight: var(--unstable_DataGrid-headWeight);
}
.order-level-card-contents .order-level-table .MuiDataGrid-columnHeader {
  height: unset !important;
}
.order-level-card-contents .order-level-table .MuiDataGrid-columnHeaders {
  max-height: 55px !important;
  min-height: 55px !important;
}

.order-level-modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80%;
  height: 80%;
  padding: 16px;
}
.order-level-modal highlight {
  background: rgba(0, 73, 84, 0.3);
  padding: 0 2px;
}
.order-level-modal .order-level-modal-contents {
  height: 100%;
}
.order-level-modal .order-level-modal-text {
  height: 85%;
}
.order-level-modal .order-level-modal-text .order-level-modal-text-scroll {
  white-space: pre-wrap;
  overflow: auto;
}
.order-level-modal .order-level-modal-footer {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  box-sizing: border-box;
}
.order-level-modal .order-level-modal-footer button {
  text-transform: none;
}

.description-body {
  height: 90%;
  display: flex;
  padding: 24px;
  padding-right: 0;
  gap: 24px;
  overflow: auto;
}
.description-body .description-right-panel {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  gap: 24px;
  margin-top: 5px;
}
.description-body .description-right-panel .description-right-panel-first-row {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 24px;
}
.description-body .description-right-panel .description-right-panel-second-row {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  gap: 24px;
}
.description-body .description-right-panel .description-right-panel-second-row .counts-autocomplete {
  width: 75%;
}
.description-body .description-right-panel .description-right-panel-second-row .description-right-panel-button {
  color: #ffffff;
  padding: 8px 22px;
}
.description-body .description-left-panel {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 24px;
  flex: 0 0 360px;
}
@media (max-width: 1300px) {
  .description-body .description-left-panel {
    flex: 0 0 286px;
  }
}
.description-body .description-left-panel .description-left-panel-alert {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2);
}
.description-body .description-left-panel .description-box,
.description-body .description-left-panel .jump-to-card {
  text-align: left;
  width: 100%;
}
.description-body .description-left-panel .description-box .description-title,
.description-body .description-left-panel .jump-to-card .description-title {
  display: flex;
  text-align: left;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
}
.description-body .description-left-panel .description-box .description-card-header,
.description-body .description-left-panel .description-box .jump-to-card-header,
.description-body .description-left-panel .jump-to-card .description-card-header,
.description-body .description-left-panel .jump-to-card .jump-to-card-header {
  padding: 12px 16px;
}
.description-body .description-left-panel .description-box .description-card-header .header-title,
.description-body .description-left-panel .description-box .jump-to-card-header .header-title,
.description-body .description-left-panel .jump-to-card .description-card-header .header-title,
.description-body .description-left-panel .jump-to-card .jump-to-card-header .header-title {
  font-weight: 600;
  font-size: 18px;
}
.description-body .description-left-panel .description-box .description-card-content,
.description-body .description-left-panel .description-box .jump-to-card-content,
.description-body .description-left-panel .jump-to-card .description-card-content,
.description-body .description-left-panel .jump-to-card .jump-to-card-content {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 16px;
  padding-top: 0;
}
.description-body .description-left-panel .description-box .description-card-content .jump-to-item-preview-mode,
.description-body .description-left-panel .description-box .jump-to-card-content .jump-to-item-preview-mode,
.description-body .description-left-panel .jump-to-card .description-card-content .jump-to-item-preview-mode,
.description-body .description-left-panel .jump-to-card .jump-to-card-content .jump-to-item-preview-mode {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
}
.description-body .description-left-panel .description-box .description-card-content .jump-to-item,
.description-body .description-left-panel .description-box .jump-to-card-content .jump-to-item,
.description-body .description-left-panel .jump-to-card .description-card-content .jump-to-item,
.description-body .description-left-panel .jump-to-card .jump-to-card-content .jump-to-item {
  cursor: pointer;
}
.description-body .description-left-panel .description-box .description-card-content .description-chips-box,
.description-body .description-left-panel .description-box .jump-to-card-content .description-chips-box,
.description-body .description-left-panel .jump-to-card .description-card-content .description-chips-box,
.description-body .description-left-panel .jump-to-card .jump-to-card-content .description-chips-box {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
  gap: 8px;
}
.description-body .description-scroll {
  display: flex;
  flex-direction: column;
  gap: 48px;
  padding-right: 24px;
  flex: 1 1 100%;
  overflow: auto;
  width: 100%;
}
.description-body .description-scroll .no-patient-card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 64px 16px;
  height: 20%;
}

.preview-project-created-content {
  text-align: left;
  padding: 8px;
}

.preview-info-alert-content {
  text-align: left;
}
.preview-info-alert-content .preview-info-alert-content-link {
  vertical-align: unset;
  cursor: pointer;
}

.project-details-modal .details-modal-box {
  align-items: flex-start;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  gap: 24px;
  overflow-y: auto;
  overflow-x: hidden;
  width: 440px;
}
.project-details-modal .details-modal-box .details-title-box {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  width: 100%;
}
.project-details-modal .details-modal-box .details-title-box .details-edit-project {
  text-transform: none;
}
.project-details-modal .details-modal-box .details-section {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  box-sizing: border-box;
  gap: 4px;
  width: 100%;
}
.project-details-modal .details-modal-box .details-section .details-versions {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
  gap: 4px;
}
.project-details-modal .details-bottom-actions {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.project-details-modal .details-bottom-actions button {
  text-transform: none;
}

.export-modal {
  width: 600px;
  height: auto;
  padding: 16px;
}
.export-modal .export-modal-form {
  width: 100%;
  gap: 8px;
}
.export-modal .export-modal-form .export-modal-line {
  padding-left: 16px;
  padding-bottom: 8px;
}
.export-modal .export-modal-form .export-modal-footer {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  box-sizing: border-box;
}
.export-modal .export-modal-form .export-modal-footer .right-side {
  width: auto;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  gap: 8px;
}
.export-modal .export-modal-form .export-modal-footer button {
  text-transform: none;
}

.category-name {
  text-transform: capitalize;
}

dropdown-text-box {
  /* Checkbox Toggle */
  /* Auto layout */
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px 18px;
  gap: 8px;
  margin-top: 8px;
  width: 465px;
  height: 20px;
  /* Inside auto layout */
  flex: none;
  order: 1;
  flex-grow: 0;
}

.dropdown-button {
  border: none;
  border-radius: 10px;
}

.modal-filter-content {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.modal-filter-content .modal-demographics-skeleton {
  width: 100%;
  height: 70px;
}

.patients-box {
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
}

.selected-patient {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  flex: 1 1 100%;
  height: 100%;
  padding-bottom: 2px;
  box-sizing: border-box;
  overflow: hidden;
}
.selected-patient .data {
  padding: 16px;
  padding-top: 0;
  margin-top: 0;
  overflow: auto;
}
.selected-patient .selected-patient-main-body-column-left {
  flex-grow: 1;
  width: 45%;
}
.selected-patient .selected-patient-main-body-column-right {
  flex-grow: 1;
  width: 475px;
  min-width: 380px;
  max-width: 600px;
}

.patient-list {
  padding: 10px;
  height: 100%;
}
.patient-list-item-container {
  width: 100%;
}
.patient-list-item-card {
  display: flex;
  align-items: center;
}
.patient-list-item-card.MuiCard-root {
  border: none;
  border-radius: 8px;
  box-shadow: none;
}
.patient-list .patient-list-info {
  width: 100%;
}
.patient-list .patient-list-info .patient-list-top {
  width: 100%;
}
.patient-list .patient-list-info .categorization {
  gap: 8px;
}

.patient-list {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.patient-list-icon-bubble {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 8px;
  border-radius: 24px;
  height: fit-content;
  width: fit-content;
  margin-right: 5%;
  flex: none;
  order: 0;
  flex-grow: 0;
}

.patient-list-item-card .MuiCardContent-root {
  display: flex;
}

.categorize-modal {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 0px;
  position: relative;
  width: 600px;
  height: fit-content;
  padding: 16px;
}

.categorize-modal-title {
  width: 100%;
  margin-bottom: 10px;
  margin-left: 6px;
}

.categorize-dropdown {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 0px;
  gap: 16px;
  width: 100%;
  height: fit-content;
  margin-left: 6px;
}

.categorize-modal-textfield {
  width: 97.5%;
  height: 218px;
  margin: 6px;
  margin-top: 16px;
}

.categorize-modal-delete-textfield {
  width: 97.5%;
  margin: 6px;
  margin-top: 16px;
}

.categorize-modal-footer {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  box-sizing: border-box;
}
.categorize-modal-footer .left-side {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}
.categorize-modal-footer .right-side {
  width: auto;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  gap: 8px;
}
.categorize-modal-footer button {
  text-transform: none;
}

.categorize-modal-footer-mark {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-end;
  padding-right: 8px;
  width: 100%;
  gap: 10px;
  box-sizing: border-box;
}
.categorize-modal-footer-mark button {
  text-transform: none;
}

.mark-dropdown {
  width: 97.5%;
}

.categorize-modal-textfield {
  height: 100%;
}

.patient-header {
  box-sizing: border-box;
  padding: 8px 10px 8px 16px;
  width: 100%;
}

.patient-header-left {
  padding: 0px;
  height: 32px;
  flex: none;
}

.reason-icon:hover {
  cursor: default;
}

.patient-header-right {
  flex-wrap: nowrap;
}
.patient-header-right .categorize-button {
  width: fit-content;
  line-height: 24px;
  letter-spacing: 0.400000006px;
  margin-right: 6px;
  white-space: nowrap;
  text-transform: none;
}

.patient-modal-select-icon {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.patient-card {
  width: 100%;
}
.patient-card .title {
  font-weight: 700;
  font-size: 20px;
}
.patient-card .content {
  padding: 16px 0 0 16px;
  gap: 8px;
}
.patient-card .content .column-for-demographics-data {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  border: 50px;
}
.patient-card .content .column {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
}
.patient-card .content .column .patient-row-title {
  margin-bottom: 4px;
  margin-top: 12px;
  font-weight: 600;
}
.patient-card .content .row {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  gap: 12px;
  margin-bottom: 0.25rem;
}
.patient-card .column-for-structured-data {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
}
.patient-card .column-for-structured-data .patient-row-title {
  padding: 0 0 0 16px;
  margin-bottom: 4px;
  margin-top: 12px;
  font-weight: 700;
  font-size: 20px;
}
.patient-card .column-for-structured-data .categorization-row-title {
  padding: 0 0 0 16px;
  margin-right: 6px;
  font-weight: 700;
  margin-left: -16px;
  font-size: 20px;
}
.patient-card .column-for-structured-data .details-table {
  width: inherit;
}
.patient-card .column-for-structured-data .details-table .details-table-title {
  font-size: 16px;
  font-weight: 400;
}
.patient-card .column-for-structured-data .prom-group-cell {
  width: 100%;
}
.patient-card .column-for-structured-data .prom-group-title {
  position: relative;
  text-align: left;
  display: inline-block;
  max-width: 180px;
  min-width: 65px;
  margin-right: auto;
  margin-top: auto;
  margin-bottom: auto;
}
.patient-card .column-for-structured-data .prom-group-label {
  text-align: center;
  display: inline-block;
  max-width: 180px;
  min-width: 75px;
  margin-top: auto;
  margin-bottom: auto;
}
.patient-card .column-for-structured-data .prom-answer-text {
  display: flex;
  align-items: center;
  min-height: 42px;
  padding: 5px 0;
}
.patient-card .column-for-structured-data .koos-subscore-row .prom-subscore-label {
  height: 3em;
}
.patient-card .column-for-structured-data .koos-subscore-row .prom-group-label {
  min-width: 56px;
}
.patient-card .column-for-structured-data .koos-subscore-row.prom-group-cell-row {
  column-gap: 4px;
}
.patient-card .column-for-structured-data .prom-group-cell-row {
  min-height: 90px;
  display: flex;
  justify-content: right;
  column-gap: 30px;
}
.patient-card .column-for-structured-data .prom-group-cell-row:not(:first-child) {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
}
.patient-card .column-for-structured-data .prom-group-label:first-child {
  margin-left: 0;
}
.patient-card .column-for-structured-data .prom-group-label:last-child {
  margin-right: 0;
}
.patient-card .column-for-structured-data .prom-questions-pill {
  display: inline-block;
  border-radius: 100px;
  background: var(--common-black-main, #000);
  color: #fff;
  font-size: 12px;
  font-weight: 400;
  line-height: 166%; /* 19.92px */
  letter-spacing: 0.4px;
  padding: 0 5px;
}
.patient-card .column-for-structured-data .prom-raw-score-pill {
  display: inline-block;
  border-radius: 100px;
  background: var(--info-main, #03a9f4);
  color: #fff;
  font-size: 12px;
  font-weight: 400;
  line-height: 166%; /* 19.92px */
  letter-spacing: 0.4px;
  padding: 0 5px;
}
.patient-card .column-for-structured-data .prom-score-pill {
  display: inline-block;
  border-radius: 100px;
  background: var(--primary-dark, #00424d);
  color: #fff;
  font-size: 12px;
  font-weight: 400;
  line-height: 166%; /* 19.92px */
  letter-spacing: 0.4px;
  padding: 0 5px;
}
.patient-card .column-for-structured-data .prom-subscore-pill {
  display: inline-block;
  border-radius: 100px;
  padding: 0 5px;
  background: rgba(0, 0, 0, 0.12);
  color: #000;
  font-size: 11px;
  font-weight: 400;
  line-height: 18.26px;
}
.patient-card .bold-weight {
  font-weight: 600;
}

.index-procedure-visit-container .index-procedure-visit-card {
  overflow: auto;
}
.index-procedure-visit-container .index-procedure-visit-card .card-title {
  font-weight: 700;
  font-family: DM Sans;
  font-size: 24px;
}
.index-procedure-visit-container .index-procedure-visit-card .index-procedure-link {
  margin: 10px;
}
.index-procedure-visit-container .index-procedure-visit-card .content {
  gap: 17px;
}
.index-procedure-visit-container .index-procedure-visit-card .content .details-table-title {
  font-family: DM Sans;
  font-size: 16px;
  font-weight: 400;
}
.index-procedure-visit-container .index-procedure-visit-card .content .section-title {
  font-family: DM Sans;
  font-weight: 700;
  font-size: 20px;
}
.index-procedure-visit-container .index-procedure-visit-card .content .visit-date {
  font-weight: 700;
  font-size: 16px;
  font-family: DM Sans;
}
.index-procedure-visit-container .index-procedure-visit-card .content .section {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding-right: 16px;
  padding-left: 16px;
}
.index-procedure-visit-container .index-procedure-visit-card .content .visit-date-label {
  font-family: DM Sans;
  font-size: 20px;
  font-weight: 700;
}
.index-procedure-visit-container .index-procedure-visit-card .content .device-details {
  padding-right: 16px;
  padding-left: 16px;
  gap: 30px;
}
.index-procedure-visit-container .index-procedure-visit-card .content .device-details .device-details-title {
  font-family: DM Sans;
  font-weight: 700;
  font-size: 20px;
}
.index-procedure-visit-container .index-procedure-visit-card .content .device-details .device-details-normal {
  font-size: 14px;
}
.index-procedure-visit-container .index-procedure-visit-card .content .device-details .device-details-used {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 32px;
  padding: 8px;
  padding-left: 0px;
}
.index-procedure-visit-container .index-procedure-visit-card .content .device-details .device-details-used .device-details-normal {
  font-size: 14px;
}
.index-procedure-visit-container .index-procedure-visit-card .content .diagnosis,
.index-procedure-visit-container .index-procedure-visit-card .content .procedure {
  gap: 4px;
}
.index-procedure-visit-container .index-procedure-visit-card .content .diagnosis .code,
.index-procedure-visit-container .index-procedure-visit-card .content .procedure .code {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
}
.index-procedure-visit-container .index-procedure-visit-card .content .diagnosis .code .text,
.index-procedure-visit-container .index-procedure-visit-card .content .procedure .code .text {
  font-size: 14px;
}
.index-procedure-visit-container .index-procedure-visit-card .content .additional-info .title {
  margin-bottom: 4px;
}
.index-procedure-visit-container .index-procedure-visit-card .content .additional-info .target-location-container,
.index-procedure-visit-container .index-procedure-visit-card .content .additional-info .indication-of-use-container,
.index-procedure-visit-container .index-procedure-visit-card .content .additional-info .device-deficient-container {
  margin-bottom: 12px;
}
.index-procedure-visit-container .index-procedure-visit-card .content .additional-info .target-location-container .header,
.index-procedure-visit-container .index-procedure-visit-card .content .additional-info .target-location-container .content,
.index-procedure-visit-container .index-procedure-visit-card .content .additional-info .indication-of-use-container .header,
.index-procedure-visit-container .index-procedure-visit-card .content .additional-info .indication-of-use-container .content,
.index-procedure-visit-container .index-procedure-visit-card .content .additional-info .device-deficient-container .header,
.index-procedure-visit-container .index-procedure-visit-card .content .additional-info .device-deficient-container .content {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}
.index-procedure-visit-container .index-procedure-visit-card .content .additional-info .target-location-container .header .edit-link,
.index-procedure-visit-container .index-procedure-visit-card .content .additional-info .indication-of-use-container .header .edit-link,
.index-procedure-visit-container .index-procedure-visit-card .content .additional-info .device-deficient-container .header .edit-link {
  margin: 5px;
  margin-left: 10px;
}
.index-procedure-visit-container .index-procedure-visit-card .content .additional-info button {
  text-transform: none;
  text-decoration: underline;
}

.indication-of-use-modal .card {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 600px;
}
.indication-of-use-modal .card .textarea {
  margin-top: 16px;
}
.indication-of-use-modal .card .actions {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}
.indication-of-use-modal .card .actions button {
  text-transform: none;
}

.visits-card .visit-item {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 8px;
  width: 100%;
}
.visits-card .visit-item .visit-data {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 6px;
  width: 60%;
  text-align: left;
}
.visits-card .visit-item .visit-data .index-procedure {
  font-weight: bold;
  text-align: left;
}
.visits-card .visit-item .visit-data .post-day-index {
  text-align: left;
}
.visits-card .visit-item .visit-data .visit-type {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
}
.visits-card .visit-item .visit-data .visit-type .label {
  font-size: 12px;
  font-weight: 700;
  line-height: 166%;
}
.visits-card .visit-item .visit-actions {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-start;
  min-width: 10%;
  flex-grow: 1;
  padding-right: 16px;
}
.visits-card .visit-item .visit-actions .date-of-usage {
  font-weight: 700;
}
.visits-card .visit-item .visit-icon-box {
  min-width: 10%;
  flex-grow: 1;
}

.visits-toggle {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding-bottom: 16px;
  padding-left: 0px;
}

.visits-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
}
.visits-header .visits-row-date {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 6px;
  max-width: 45%;
  min-width: 30%;
  flex-grow: 1;
}
.visits-header .visits-row {
  padding: 6px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}
.visits-header .visits-row .left-divider {
  padding-left: 16px;
}
.visits-header .visits-row .right-divider {
  padding-left: 16px;
}

.clinical-notes-note highlight {
  background: rgba(0, 73, 84, 0.3);
  padding: 0 2px;
}

.visitsDiagnosesProcedures .card-title {
  font-weight: 600;
  font-family: DM Sans;
  font-size: 20px;
}
.visitsDiagnosesProcedures .tabs {
  width: 100%;
}
.visitsDiagnosesProcedures .tabs .tab {
  flex: 1 1 100%;
}
.visitsDiagnosesProcedures .tabs .tab .tab-title {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 6px;
  text-transform: capitalize;
}
.visitsDiagnosesProcedures .tabs-post-label {
  margin-top: 16px;
}

.code-list-table .head .code {
  width: 16%;
}
.code-list-table .head .code-description,
.code-list-table .head .visit {
  width: 42%;
}
.code-list-table .body td {
  vertical-align: top;
}
.code-list-table .chip-container {
  height: 100%;
}
.code-list-table .chip-container .chip-label {
  white-space: normal;
  text-overflow: clip;
  overflow-wrap: break-word;
}
.code-list-table .index-procedure {
  font-weight: bold;
}

.spike-container {
  left: 100px;
  display: flex;
  flex-wrap: wrap;
  overflow-y: auto;
  height: 100%;
  gap: 3rem;
  padding: 1rem;
  position: fixed;
}

.test-chart {
  width: 600px;
  height: 400px;
  margin-bottom: 3rem;
}

.make-modal-card {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  height: 100%;
  width: 100%;
}
.make-modal-card .make-modal-header {
  box-sizing: border-box;
  width: 100%;
}
.make-modal-card .make-modal-content {
  box-sizing: border-box;
  flex-grow: 1;
  max-height: 100%;
  overflow-y: auto;
  overflow-y: overlay;
  padding: 24px;
  width: 100%;
}
.make-modal-card .make-modal-footer {
  box-sizing: border-box;
  width: 100%;
}

.date-range {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.standard-drawer-footer {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  box-sizing: border-box;
  gap: 8px;
  width: 100%;
}
.standard-drawer-footer button {
  text-transform: none;
}
.standard-drawer-footer-align-between {
  justify-content: space-between;
}
.standard-drawer-footer-align-end {
  justify-content: flex-end;
}
.standard-drawer-footer-align-start {
  justify-content: flex-start;
}

.chart-bullet-point {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 8px;
}
.chart-bullet-point span {
  border-radius: 50%;
  display: inline-block;
  width: 16px;
  height: 16px;
}

.shared-footer,
.public-shared-footer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  width: auto;
  text-align: left;
  flex-wrap: wrap;
}
.shared-footer a,
.public-shared-footer a {
  padding: 4px 4px 4px 4px;
  color: inherit;
  height: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-right: 1px rgba(0, 0, 0, 0.0705882353) solid;
  white-space: nowrap;
}
.shared-footer p,
.public-shared-footer p {
  padding-left: 4px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
}

.public-shared-footer {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  height: auto;
  padding-left: 120px;
  color: white;
  padding-right: 100px;
}
.public-shared-footer a {
  border-right: 1px rgba(255, 255, 255, 0.0705882353) solid;
  white-space: nowrap;
}

.activity-card {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 16px;
  padding: 16px;
}

.patient-activity-drawer .modal-header-left .drawer-title,
.project-activity-drawer .modal-header-left .drawer-title {
  font-weight: 700;
}
.patient-activity-drawer .content,
.project-activity-drawer .content {
  padding: 32px 24px;
}

.project-metric-cards-container {
  display: grid;
  gap: 24px;
  grid-template-columns: 542px 323px;
  grid-auto-rows: 170px;
}
@media (max-width: 1366px) {
  .project-metric-cards-container {
    grid-template-columns: 1fr;
  }
}
.project-metric-cards-container .card-content {
  padding: 16px 24px 24px 24px;
  justify-content: space-between;
}
.project-metric-cards-container .total-patients .card-content,
.project-metric-cards-container .breadkdown-by-gender .card-content {
  display: flex;
  flex-direction: column;
  gap: 16px;
  height: 100%;
  box-sizing: border-box;
}
.project-metric-cards-container .total-patients .card-content .chart-container,
.project-metric-cards-container .breadkdown-by-gender .card-content .chart-container {
  flex: 1 1 auto;
}
.project-metric-cards-container .total-patients .card-content .chart-labels,
.project-metric-cards-container .breadkdown-by-gender .card-content .chart-labels {
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 8px;
}
.project-metric-cards-container .total-patients {
  grid-row: 3 span;
}
.project-metric-cards-container .total-patients .helper-text {
  width: 392px;
  margin: 0 auto;
}
.project-metric-cards-container .breadkdown-by-gender {
  grid-row: 3 span;
}
.project-metric-cards-container .breadkdown-by-gender .chart-container {
  max-height: 130px;
}
.project-metric-cards-container .breadkdown-by-gender .legend-text {
  font-size: 12px;
}
.project-metric-cards-container .card-title {
  font-weight: 700;
}
.project-metric-cards-container .helper-text {
  text-align: center;
}

.project-metric-cards-skeleton .card-content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.update-project-drawer .header,
.create-project-drawer .header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.update-project-drawer .header .drawer-title,
.create-project-drawer .header .drawer-title {
  font-weight: 700;
}
.update-project-drawer .project-drawer-content,
.create-project-drawer .project-drawer-content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  gap: 24px;
  height: 100%;
}
.update-project-drawer .project-drawer-content .project-details-container,
.create-project-drawer .project-drawer-content .project-details-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  gap: 24px;
  width: 100%;
}
.update-project-drawer .project-drawer-content .protocol-section,
.create-project-drawer .project-drawer-content .protocol-section {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 32px;
}
.update-project-drawer .project-drawer-content .protocol-section .subsection,
.create-project-drawer .project-drawer-content .protocol-section .subsection {
  width: 100%;
}
.update-project-drawer .project-drawer-content .protocol-section .subsection p,
.create-project-drawer .project-drawer-content .protocol-section .subsection p {
  margin-bottom: 16px;
}
.update-project-drawer .project-drawer-content .protocol-section .subsection .visit-range-section-chevron,
.create-project-drawer .project-drawer-content .protocol-section .subsection .visit-range-section-chevron {
  line-height: 0.5;
  margin-bottom: 35px;
  margin-right: -3px;
}
.update-project-drawer .project-drawer-content .prioritization-container,
.create-project-drawer .project-drawer-content .prioritization-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  box-sizing: border-box;
  gap: 32px;
}
.update-project-drawer .project-drawer-content .prioritization-container .prioritation-info-text,
.create-project-drawer .project-drawer-content .prioritization-container .prioritation-info-text {
  letter-spacing: 0.17px;
}
.update-project-drawer .project-drawer-content .prioritization-container .prioritation-info-text .icon,
.create-project-drawer .project-drawer-content .prioritization-container .prioritation-info-text .icon {
  margin-left: 4px;
  vertical-align: middle;
}
.update-project-drawer .project-drawer-content .prioritization-container .important-keywords-box,
.create-project-drawer .project-drawer-content .prioritization-container .important-keywords-box {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 16px;
}
.update-project-drawer .project-drawer-content .prioritization-container .important-keywords-box .important-keywords-header,
.create-project-drawer .project-drawer-content .prioritization-container .important-keywords-box .important-keywords-header {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: 16px;
}
.update-project-drawer .project-drawer-content .prioritization-container .important-keywords-box .important-keywords-items,
.create-project-drawer .project-drawer-content .prioritization-container .important-keywords-box .important-keywords-items {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 32px;
  width: 100%;
}
.update-project-drawer .project-drawer-content .prioritization-container .important-keywords-box .important-keywords-items .keyword-row,
.create-project-drawer .project-drawer-content .prioritization-container .important-keywords-box .important-keywords-items .keyword-row {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 16px;
  width: 100%;
}
.update-project-drawer .project-drawer-content .prioritization-container .important-keywords-box .important-keywords-items .keyword-row .important-keywords-inputs,
.create-project-drawer .project-drawer-content .prioritization-container .important-keywords-box .important-keywords-items .keyword-row .important-keywords-inputs {
  flex: 1 1 100%;
}
.update-project-drawer .project-drawer-content .prioritization-container .important-keywords-box .important-keywords-items .keyword-row .remove-icon,
.create-project-drawer .project-drawer-content .prioritization-container .important-keywords-box .important-keywords-items .keyword-row .remove-icon {
  flex: 1 0 28px;
}
.update-project-drawer .project-drawer-content .prioritization-container .important-keywords-box .add-keyword-button,
.create-project-drawer .project-drawer-content .prioritization-container .important-keywords-box .add-keyword-button {
  text-transform: none;
}
.update-project-drawer .project-drawer-content .prioritization-container .clinical-notes-box,
.create-project-drawer .project-drawer-content .prioritization-container .clinical-notes-box {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  gap: 16px;
}

.contact-us-modal {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 12px;
  height: 100%;
}

.demographic-breakdown-card .card-title {
  margin-bottom: 48px;
}
.demographic-breakdown-card .chart-cotainer-lg {
  height: 324px;
}

.filter-dataset-tabs {
  width: 100%;
}
.filter-dataset-tabs .MuiTab-root {
  flex: 1 1 100%;
}

.codes-layout {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  height: 100%;
  width: 100%;
}
.codes-layout .help-text {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 9.5px;
}
.codes-layout .buttons {
  margin-top: 24px;
}
.codes-layout .buttons .count {
  margin-left: 3px;
}
.codes-layout .inputs {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  gap: 20px;
  margin-top: 20px;
}
.codes-layout .inputs .MuiTextField-root {
  flex: 1 1 100%;
}
.codes-layout .snackbar-containter {
  bottom: 140px;
  position: absolute;
  width: max-content;
}
.codes-layout .snackbar-containter button {
  text-transform: none;
}
.codes-layout .table {
  flex: 1 1 100%;
  margin-top: 20px;
}
.codes-layout .table .unrelated-text {
  font-style: italic;
}
.codes-layout .table .MuiTableCell-head {
  padding-top: 16px;
  padding-bottom: 16px;
}
.codes-layout .table .MuiCheckbox-root {
  padding: 0;
}
.codes-layout .table .head .description-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  width: auto;
  gap: 20px;
}
.codes-layout .table .head .patients {
  width: 90px;
  max-width: 90px;
  overflow-wrap: break-word;
}
.codes-layout .table .head .encounters {
  width: 95px;
  max-width: 95px;
  overflow-wrap: break-word;
}
.codes-layout .table .row > * {
  border-bottom: unset;
}
.codes-layout .table .row .container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}
.codes-layout .table .row .container .description {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  display: inline-flex;
  gap: 8px;
}
.codes-layout .table .row .container .description .title {
  font-weight: 600;
}
.codes-layout .table .row .container .description .badge {
  padding: 0px 6px;
}
.codes-layout .table .row-collapse > .MuiTableCell-root {
  padding: 0;
}
.codes-layout .sub-table .MuiCheckbox-root {
  padding: 0;
}
.codes-layout .sub-table .row > * {
  border-bottom: unset;
}
.codes-layout .sub-table .row .description-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  width: auto;
  gap: 20px;
}
.codes-layout .sub-table .row .patients {
  width: 90px;
  max-width: 90px;
  overflow-wrap: break-word;
}
.codes-layout .sub-table .row .encounters {
  width: 95px;
  max-width: 95px;
  overflow-wrap: break-word;
}
.codes-layout .sub-table .row .description {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
}

.filter-dataset-footer {
  box-sizing: border-box;
  width: 100%;
}

.mark-visit-modal {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 0px;
  position: relative;
  width: 600px;
  height: fit-content;
}

.settings-header {
  width: 100vh;
  background: #092b3c;
  display: inline-block;
  height: fit-content;
}
.settings-header .settings-header-text {
  margin: 10px auto;
  display: inline-block;
  color: #ffffff;
}
.settings-header .settings-header-logo {
  margin: auto 10px;
}
.settings-header .background-dots-left {
  height: 120px;
}
.settings-header .background-dots-right {
  height: 120px;
}

.settings-container {
  width: 100%;
  height: 83vh;
  overflow: scroll;
}

.company-settings-item {
  width: fit-content;
  max-width: 75vw;
  text-align: left;
}
.company-settings-item highlight {
  background: rgba(0, 73, 84, 0.3);
}

.settings-content-box {
  width: 100%;
}

div.categorize-modal-imdrf {
  padding-right: 2px;
}
div.categorize-modal-imdrf .MuiInputBase-input {
  overflow: hidden;
  text-overflow: ellipsis;
}

.categorize-modal-black .MuiInputBase-input.Mui-disabled {
  -webkit-text-fill-color: #000000;
}

.imdrf-table .MuiTableCell-root {
  vertical-align: top;
}
